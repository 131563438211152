import React from "react";
import logo from './logo512.png';
import './Post.css';

export default function Post({post}) {
    const { id, title, content, created_at, image } = post;
  return (
    <div className="post-card">
      <img
        src={logo}
        className="profile-pic"
        width="50px"
        height="50px"
        alt="profile"
      />
      <div className="post-container">
        <div className="username">@{id.split('|')[0]}</div>
        <div className="date">{new Date(created_at).toLocaleDateString()}</div>
        <h3>{title}</h3>
        <div className="content">
            {content}
        </div>
        {
            image && 
            <img className='post-image' src={image} />
        }
      </div>
    </div>
  );
}
