import React, { useRef, useState } from 'react'
import './SendPost.css';

export default function SendPost() {

    const [imageBase64, setImageBase64] = useState(null);
    const title = useRef('');
    const content = useRef('');
    const username = useRef('');

    function handleFileInput(file) {
        let reader = new FileReader();
        reader.onload = e => setImageBase64(e.target.result);
        if(file)
            reader.readAsDataURL(file);
        else
            setImageBase64(null);
    }

    async function Submit () {
        const response = await fetch('https://workers.abemark45.workers.dev/posts', {
            method: 'POST',
            body: JSON.stringify({
                username: username.current,
                title: title.current,
                content: content.current,
                image: imageBase64
            }),
            headers: {
                'content-type': 'application/json'
            }
        });

        console.log('status: ', response);
        if(response.status === 200)
            alert('Posted Successfullty!');
        else {
            const responseBody = await response.json();
            alert(responseBody);
        }
    }

    return (
        <div className='card'>
            Title:
            <input type='text' name='username' placeholder='username..' onChange={e => username.current = e.target.value}/>
            Title:
            <input type='text' name='title' placeholder='title..' onChange={e => title.current = e.target.value}/>
            Content:
            <input type='text' name='content' placeholder='content..' onChange={e => content.current = e.target.value}/>
            image:
            <input id='image' type='file' accept='image/png, image/jpeg' name='image'
            placeholder='image..' onChange={e => handleFileInput(e.target.files[0])} />
            <button onClick={Submit}>Submit</button>
            {
                imageBase64 &&
                <img className='image-upload' src={imageBase64} height='500px' alt='pic'/>
            }
        </div>
    )
}
